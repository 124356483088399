import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-luminarias',
  templateUrl: './luminarias.component.html',
  styleUrls: ['./luminarias.component.scss']
})
export class LuminariasComponent implements OnInit {

  slideConfig1 = {  
    "slidesToShow": 1, 
     "fade": true,
     "arrows": true,
     nextArrow: '<div class="circle-arrow slick-right2"><i class="material-icons">navigate_next</i></div>',
     prevArrow: '<div class="circle-arrow slick-left2"><i class="material-icons">navigate_before</i></div>',
  };



  constructor() { }

  ngOnInit() {
  }

  slickInit(e) {
  }

}
