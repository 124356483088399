import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'; 
import { AppCustomPreloader } from './app-routing-loader';

import { HomeComponent } from './components/home/home.component';
import { QuienesSomosComponent } from './components/quienes-somos/quienes-somos.component';
import { ContactanosComponent } from './components/contactanos/contactanos.component';
import { MueblesDeDisenoComponent } from './components/muebles-de-diseno/muebles-de-diseno.component';
import { MueblesDeTerrazaComponent } from './components/muebles-de-terraza/muebles-de-terraza.component';
import { LuminariasComponent } from './components/luminarias/luminarias.component';
import { AlfombrasComponent } from './components/alfombras/alfombras.component';


const routes: Routes = [
  { path: '',  redirectTo: 'home',  pathMatch: 'full', data: { preload: true } },

  {
    path:  'home',
    component: HomeComponent
  },
  {
    path: 'quienes-somos',
    component: QuienesSomosComponent
  },
  
  {
    path: 'muebles-de-diseno',
    component: MueblesDeDisenoComponent
  },
  {
    path: 'muebles-de-terraza',
    component: MueblesDeTerrazaComponent
  },
  {
    path: 'luminarias',
    component: LuminariasComponent
  },
  {
    path: 'alfombras',
    component: AlfombrasComponent
  },
  {
    path: 'contactanos',
    component: ContactanosComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
  //providers: [AppCustomPreloader]
})


export class AppRoutingModule { }
