import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostService } from './service/post.service';
import { PageService } from './service/page.service';
import { Router, NavigationEnd, ActivatedRoute } from'@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    PostService,
    PageService
  ]
})
export class AppComponent implements OnInit {
  
  public postList = new Array();
  public pageList = new Array(); 
  constructor(
    private postService: PostService,
    private activatedRoute: ActivatedRoute,
    private router:Router


  ){ }
  ngOnInit(){
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
      const nav = document.getElementById('mainMenu');
      nav.classList.remove('show') ;

      const navButton = document.getElementById('hamburgues');
    navButton.classList.remove('buttonColor')  
  });


    this.preload();
  }
 

  preload() {
     setTimeout (() => {
      const load = document.getElementById('preload');
      load.classList.add('d-none');

      }, 5000);
 
  
  }



}
