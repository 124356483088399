import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  isCollapsed: boolean;
 
  constructor(
 //   private activatedRoute: ActivatedRoute
  ) {
  
  }

  ngOnInit() {
    this.isCollapsed = true;
//     console.log('mostrar ' + this.activatedRoute.snapshot);

  }

  mostranav(){
    const navButton = document.getElementById('hamburgues');
    navButton.classList.toggle('buttonColor')  
    const nav = document.getElementById('mainMenu');
    nav.classList.toggle('show')  
  }

}
