import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Location, LocationStrategy,  PathLocationStrategy } from '@angular/common';

import { RouterModule } from '@angular/router';


import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 

import { LazyLoadDirective } from './directives/lazy-load.directive';
 
import { LayoutHeaderComponent } from './shared/layout-header/layout-header.component';
import { LayoutFooterComponent } from './shared/layout-footer/layout-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PostComponent } from './post/post.component';
import { HomeComponent } from './components/home/home.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { QuienesSomosComponent } from './components/quienes-somos/quienes-somos.component';
import { ContactanosComponent } from './components/contactanos/contactanos.component';
import { MueblesDeDisenoComponent } from './components/muebles-de-diseno/muebles-de-diseno.component';
import { MueblesDeTerrazaComponent } from './components/muebles-de-terraza/muebles-de-terraza.component';
import { LuminariasComponent } from './components/luminarias/luminarias.component';
import { AlfombrasComponent } from './components/alfombras/alfombras.component';


@NgModule({
  declarations: [
    AppComponent, 
    LazyLoadDirective,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    PostComponent,
    HomeComponent,
    QuienesSomosComponent,
    ContactanosComponent,
    MueblesDeDisenoComponent,
    MueblesDeTerrazaComponent,
    LuminariasComponent,
    AlfombrasComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    SlickCarouselModule,
    RouterModule.forRoot([])

  ],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]

})
export class AppModule { }
