import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,   
    "focusOnSelect": true,
    centerMode: true,
    "autoplay": false, 
    "navSpeed": 150,
    "arrows": true,
    "fade": true,
    "asNavFor": '.slide2',
    cssEase: 'linear',
    nextArrow: '<div class="circle-arrow slick-right1"><i class="material-icons">navigate_next</i></div>',
    prevArrow: '<div class="circle-arrow slick-left1"><i class="material-icons">navigate_before</i></div>',
    responsive: [
      {
        breakpoint: 581,
        settings: { 
          infinite: false,
          autoplay: false,
          "arrows": false,
           

        }
      } 
    ]
  };
  slideConfig2 = {  
    "slidesToShow": 4, 
    "focusOnSelect": true,
    "autoplay": false, 
    "navSpeed": 150,
    "arrows": true,
    "asNavFor": '.slide1',
     
    
 
  };

  


  constructor() { }

  ngOnInit() {
  }

  scroll() {
    const el = document.getElementById('contact');
    el.scrollIntoView({ behavior: 'smooth'});
    

   }
  slickInit(e) {
  }
  slickInit2(e) {
  }

}
